import {
  match as createMatchFn,
  type Match,
  type MatchFunction,
} from "path-to-regexp";
import { Environment, fetchQuery, loadQuery } from "react-relay";
import { getRequest } from "relay-runtime";
import { getCurrentUser } from "./Auth";
import { ForbiddenError, NotFoundError } from "./errors";
import { Seo, UrlData, type Route, type RouterResponse } from "./router.types";
import { Config } from "../config";

/**
 * Converts the URL path string to a RegExp matching function.
 *
 * @see https://github.com/pillarjs/path-to-regexp
 */
const matchUrlPath: (
  pattern: string[] | string,
  path: string,
) => Match<{ [key: string]: string }> = (() => {
  const cache = new Map<string, MatchFunction<{ [key: string]: string }>>();
  return function matchUrlPath(pattern: string[] | string, path: string) {
    const key = Array.isArray(pattern) ? pattern.join("::") : pattern;
    let fn = cache.get(key);
    if (fn) return fn(path);
    fn = createMatchFn(pattern, { decode: decodeURIComponent });
    cache.set(key, fn);
    return fn(path);
  };
})();

const resolveRouteCurry =
  (
    relay: Environment,
    config: Config,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    routes: readonly Route<any, any>[],
    postProcessSeo: (urlData: UrlData, config: Config, seo: Seo) => Seo,
  ) =>
  async (urlData: UrlData): Promise<RouterResponse> => {
    try {
      // Find the first route matching the provided URL path string
      for (let i = 0, route; i < routes.length; i++) {
        route = routes[i];
        const match = matchUrlPath(route.path, urlData.path);

        if (!match) continue;

        urlData.params = match.params;

        // Prepare GraphQL query variables
        const variables =
          typeof route.variables === "function"
            ? route.variables(urlData)
            : route.variables
              ? route.variables
              : Object.keys(match.params).length === 0
                ? {}
                : match.params;

        // If `auth` variable is present in the route's GraphQL query
        // and the user's authentication state is not known yet, set it to true.
        if (route.query && typeof route.query !== "function") {
          const { operation } = getRequest(route.query);
          if (operation.argumentDefinitions.some((x) => x.name === "auth")) {
            variables.auth = getCurrentUser(relay) === undefined;
          }
        }

        // Fetch GraphQL query response and load React component in parallel
        const [component, data] = await Promise.all([
          route.component?.().then((x) => x.default),
          route.query &&
            (typeof route.query === "function"
              ? route.query(urlData)
              : route.useLoadQuery
                ? loadQuery(relay, route.query, variables, {
                    fetchPolicy: "store-or-network",
                  })
                : // Had to change store-or-network mode to network-only because of problems with discounts reloading
                  fetchQuery(relay, route.query, variables, {
                    fetchPolicy: "network-only",
                  }).toPromise()),
        ]);

        // Check if the route requires an authenticated user
        if (route.authorize) {
          const user = getCurrentUser(relay);
          if (
            !user ||
            (typeof route.authorize === "function" && !route.authorize(user))
          ) {
            throw new ForbiddenError();
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response = route.response(data as any, urlData);

        if (response)
          return {
            component,
            props: response.props,
            ...postProcessSeo(urlData, config, response),
          };
      }

      throw new NotFoundError();
    } catch (err) {
      return {
        title:
          err instanceof NotFoundError ? "Page not found" : "Application error",
        error: err as Error,
      };
    }
  };

export { resolveRouteCurry, type Route, type RouterResponse as RouteResponse };
