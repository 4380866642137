/**
 * @generated SignedSource<<2d191a17e7698d857a18d236e2f2358b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthQuery$variables = {};
export type AuthQuery$data = {
  readonly cart: {
    readonly " $fragmentSpreads": FragmentRefs<"CartFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"Auth_me">;
};
export type AuthQuery = {
  variables: AuthQuery$variables;
  response: AuthQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountSum",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Auth_me"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Cart",
        "kind": "LinkedField",
        "name": "cart",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CartFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "referralCnt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "attractedPurchasesAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Cart",
        "kind": "LinkedField",
        "name": "cart",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CartItem",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isVoucher",
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "navName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maker",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullSum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Discounts",
            "kind": "LinkedField",
            "name": "discounts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Discount",
                "kind": "LinkedField",
                "name": "discounts",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "discountedSum",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "612e907cd12c87ec89cf0468b8e34197",
    "id": null,
    "metadata": {},
    "name": "AuthQuery",
    "operationKind": "query",
    "text": "query AuthQuery {\n  ...Auth_me\n  cart {\n    ...CartFragment\n    id\n  }\n}\n\nfragment Auth_me on Query {\n  me {\n    ...Auth_user\n    id\n  }\n}\n\nfragment Auth_user on User {\n  id\n  username\n  email\n  phone\n  name\n  referralCnt\n  attractedPurchasesAmount\n}\n\nfragment CartFragment on Cart {\n  items {\n    id\n    amount\n    price\n    product {\n      isVoucher\n      id\n    }\n    ...CartItemFragment\n  }\n  fullSum\n  discounts {\n    discounts {\n      name\n      discount\n      discountedSum\n      discountSum\n    }\n    discount\n    discountSum\n  }\n  total\n}\n\nfragment CartItemFragment on CartItem {\n  id\n  product {\n    id\n    name\n    navName\n    maker\n    image\n    isVoucher\n  }\n  price\n  amount\n}\n"
  }
};
})();

(node as any).hash = "da6b161aed91cf3479070e6e266a8c00";

export default node;
