import { RecordMap } from "relay-runtime/lib/store/RelayStoreTypes";
import { load } from "./ssrUtil";
import {
  RequestParameters,
  Variables,
  GraphQLResponse,
  Environment,
  FetchFunction,
  Network,
  RecordSource,
  Store,
} from "relay-runtime";

type Config = {
  fetchFunction: FetchFunction;
  records?: ConstructorParameters<typeof RecordSource>[0];
};

export function createRelay(config: Config): Environment {
  const recordSource = new RecordSource(config.records || {});
  const store = new Store(recordSource);

  // Typescript используется не везде, поэтому проверяем
  if (!config.fetchFunction) {
    throw new Error("fetchFunction is required");
  }
  const network = Network.create(config.fetchFunction);

  return new Environment({
    store,
    network,
    handlerProvider: null,
  });
}

const networkFetch =
  (endpointUrl: string): FetchFunction =>
  (
    request: RequestParameters,
    variables: Variables,
  ): Promise<GraphQLResponse> => {
    return fetch(endpointUrl, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify({ query: request.text, variables }),
      credentials: "include",
    }).then((res) => res.json());
  };

export const createWebRelay = () => {
  const data = load<RecordMap>("data");
  return createRelay({
    fetchFunction: networkFetch("/api"),
    records: data,
  });
};
