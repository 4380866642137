// Parses out the initial application state (config, Relay store)
// injected into HTML markup (see `public/index.html`)
// https://developer.mozilla.org/docs/Web/HTML/Element/script#embedding_data_in_html
export function load<T = unknown>(elementId: string): T | undefined {
  const el = document.getElementById(elementId) as HTMLScriptElement;
  const text = el.text.trim();
  if (text) {
    return JSON.parse(text);
  }
  return undefined;
}
