import { Config } from "../config";
import { Seo, UrlData } from "./router.types";

const defaultSeo = {
  title: "Купить орехи и сухофрукты в Санкт-Петербурге",
  description:
    "Интернет-магазин орехов и сухофруктов. У нас вы можете купить орехи и сухофрукты на любой вкус недорого и по хорошей цене",
  keywords:
    "орехи, сухофрукты, купить орехи, купить сухофрукты, орехи в Санкт-Петербурге, сухофрукты в Санкт-Петербурге",
  viewPort:
    "width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=1",
  robots: "index, follow",
  canonicalURL: (urlData: UrlData, config: Config) =>
    config.app.origin + urlData.path,
  image: "/logo.png",
  ogCanonicalURL: (urlData: UrlData, config: Config) =>
    config.app.origin + urlData.path,
  ogTitle: "Купить орехи и сухофрукты в Санкт-Петербурге",
  ogDescription: "Интернет-магазин орехов и сухофруктов",
  ogType: "website",
  ogImage: "/logo.png",
};

const coalesce = (
  urlData: UrlData,
  config: Config,
  a: string | undefined,
  b: string | ((urlData: UrlData, config: Config) => string),
) => a ?? (typeof b === "function" ? b(urlData, config) : b);

export const postProcessSeo = (
  urlData: UrlData,
  config: Config,
  seo: Seo,
): Seo => {
  return {
    title: coalesce(urlData, config, seo.title, defaultSeo.title),
    description: coalesce(
      urlData,
      config,
      seo.description,
      defaultSeo.description,
    ),
    viewPort: coalesce(urlData, config, seo.viewPort, defaultSeo.viewPort),
    robots: coalesce(urlData, config, seo.robots, defaultSeo.robots),
    canonicalURL: coalesce(
      urlData,
      config,
      seo.canonicalURL,
      defaultSeo.canonicalURL,
    ),
    image: coalesce(urlData, config, seo.image, defaultSeo.image),
    ogCanonicalURL: coalesce(
      urlData,
      config,
      seo.ogCanonicalURL,
      defaultSeo.ogCanonicalURL,
    ),
    ogTitle: coalesce(urlData, config, seo.ogTitle, defaultSeo.ogTitle),
    ogDescription: coalesce(
      urlData,
      config,
      seo.ogDescription,
      defaultSeo.ogDescription,
    ),
    ogType: coalesce(urlData, config, seo.ogType, defaultSeo.ogType),
    ogImage: coalesce(urlData, config, seo.ogImage, defaultSeo.ogImage),
  };
};
