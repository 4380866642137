/**
 * Client-side application settings for the local development environment.
 */
const local = {
  // Core application settings
  app: {
    name: "MadNuts",
    origin: "http://localhost:3000",
    catalogRoot: "/catalog",
    cartPath: "/cart",
    env: "local" as "local" | "test" | "prod",
    useProxiedUploads: true,
  },
  // GraphQL API and OAuth endpoint(s)
  api: {
    origin: "http://localhost:8080",
    prefix: "",
    proxyPath: "/api/proxy",
    path: "/api",
    authPath: "/auth",
  },
};
export type Config = typeof local;

/**
 * Client-side application settings for the test / QA environment.
 */
const test: Config = {
  app: {
    ...local.app,
    origin: "https://test.madnuts.ru",
    env: "test",
  },
  api: {
    ...local.api,
    origin: "https://test.madnuts.ru",
  },
};

/**
 * Client-side application settings for the production environment.
 */
const prod: Config = {
  app: {
    ...local.app,
    origin: "https://madnuts.ru",
    env: "prod",
  },
  api: {
    ...local.api,
    origin: "https://madnuts.ru",
  },
};

export { local, test, prod };
export default { local, test, prod };
