import { Box, BoxProps, Link } from "@mui/material";
import React from "react";
import { useNavigate } from "../core";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

export type AppLogoProps = BoxProps;

export const AppLogo = (props: AppLogoProps) => {
  const navigate = useNavigate();
  const { sx, ...otherProps } = props;

  return (
    <Link color="inherit" underline="none" href="/" onClick={navigate}>
      <Box
        component="img"
        sx={{
          height: 50,
          width: 50,
          maxHeight: { xs: 50, md: 50 },
          maxWidth: { xs: 50, md: 50 },
          verticalAlign: "middle",
          ...sx,
        }}
        alt="MadNuts Logo."
        src="/logo.png"
        {...otherProps}
      />
    </Link>
  );
};
