/**
 * @generated SignedSource<<ecb90aa0bfd74b040a110329f6324a3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateUserInput = {
  id?: string | null;
  username?: string | null;
  name?: string | null;
  oldPassword?: string | null;
  password?: string | null;
  phone?: string | null;
  admin?: boolean | null;
  inviteCode?: string | null;
  intellectualDiscountWord?: string | null;
  coupons?: ReadonlyArray<UpdateUserInput_coupons> | null;
};
export type UpdateUserInput_coupons = {
  id?: string | null;
  discount?: number | null;
  startTime?: any | null;
  finishTime?: any | null;
  couponTypeId?: string | null;
};
export type AuthProviderCreateUserMutation$variables = {
  input: UpdateUserInput;
};
export type AuthProviderCreateUserMutation$data = {
  readonly createUser: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"Auth_user">;
    } | null;
  } | null;
};
export type AuthProviderCreateUserMutation = {
  variables: AuthProviderCreateUserMutation$variables;
  response: AuthProviderCreateUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "dryRun",
    "value": false
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Literal",
    "name": "signIn",
    "value": true
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthProviderCreateUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUserPayload",
        "kind": "LinkedField",
        "name": "createUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Auth_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthProviderCreateUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUserPayload",
        "kind": "LinkedField",
        "name": "createUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralCnt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attractedPurchasesAmount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "caef0e60fb5a50e9541c23bf9787365b",
    "id": null,
    "metadata": {},
    "name": "AuthProviderCreateUserMutation",
    "operationKind": "mutation",
    "text": "mutation AuthProviderCreateUserMutation(\n  $input: UpdateUserInput!\n) {\n  createUser(input: $input, signIn: true, dryRun: false) {\n    user {\n      ...Auth_user\n      id\n    }\n  }\n}\n\nfragment Auth_user on User {\n  id\n  username\n  email\n  phone\n  name\n  referralCnt\n  attractedPurchasesAmount\n}\n"
  }
};
})();

(node as any).hash = "7d0f14f783f6ebed7df1cebbf6a958f3";

export default node;
